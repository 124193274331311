import { useLocation } from 'react-router-dom';
import React from 'react';

import { REFERRAL_APPT_TYPE } from '../constants/appointment';
import { AppointmentContext } from '../utils/context';

const useBookApptFlowSegmentTrackerOnPageView = (appointmentType, trackFn) => {
  const location = useLocation();
  const [appointment] = React.useContext(AppointmentContext);

  React.useEffect(() => {
    if (appointment && appointment.AppointmentTime) {
      if (appointmentType === REFERRAL_APPT_TYPE) {
        trackFn(
          location.pathname,
          appointmentType,
          appointment.AppointmentTime.provider_role
        );
      } else {
        trackFn(location.pathname, appointmentType);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentType, appointment, location]);
};

export default useBookApptFlowSegmentTrackerOnPageView;
