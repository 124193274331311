const regex = {
  name: /^[a-zA-Z\s]*$/,
  // eslint-disable-next-line no-useless-escape
  email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  atLeastOneLetter: /[a-z]+/,
  atLeastOneUpperCase: /[A-Z]+/,
  atLeastOneDigit: /\d/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
};

export default regex;
