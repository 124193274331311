import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const OshiNextButton = React.forwardRef(
  (
    {
      disabled,
      onClick,
      buttonTitle,
      styles = {},
      containerStyles = {},
      buttonContainerStyles = {},
      children = null,
      isLoading,
    },
    ref
  ) => {
    return (
      <Container style={{ ...containerStyles }}>
        <NextButtonContainer style={{ ...buttonContainerStyles }}>
          <NextButton
            disabled={disabled}
            isLoading={isLoading}
            onClick={onClick}
            styles={styles}
            ref={ref}
          >
            {buttonTitle}
          </NextButton>
          {children}
        </NextButtonContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex: 1;
  @media only screen and (min-width: 769px) {
    align-items: flex-start;
    margin-top: 24px;
  }
`;
const NextButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    justify-content: left;
  }
`;

const NextButton = styled(Button)`
  width: 100%;
  & + * {
    margin-top: 12px;
  }
  @media only screen and (min-width: 769px) {
    & + * {
      margin-top: 24px;
    }
  }
`;

export default OshiNextButton;
