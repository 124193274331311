const useCookieProcessing = () => {
  const getCookieKeyValuePair = (cookieParam) =>
    document.cookie
      .split(/;(\s)?/)
      .find((item) => item.startsWith(`${cookieParam}=`)) || null;

  const getCookieValue = (cookiePairValue) => {
    return cookiePairValue.slice(cookiePairValue.indexOf('=') + 1);
  };

  return {
    getCookieValue,
    getCookieKeyValuePair,
  };
};

export default useCookieProcessing;
