import { createStore, applyMiddleware } from 'redux';
import { createTracker } from 'redux-segment';
import rootReducer from './reducers';

const tracker = createTracker();

const createStoreWithMiddleware = applyMiddleware(tracker)(createStore);
const store = createStoreWithMiddleware(rootReducer);

export default store;
