import React from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';

function ErrorMessage({ showCheckMark, formik, error, isValid, value }) {
  const color = isValid ? palette.coolGray : palette.coolGray800;

  return (
    <Container>
      <CheckContainer>
        {showCheckMark &&
          ((isValid && value.length > 0) ||
            (formik && formik.isValid && value.length > 0)) && (
            <svg
              width={16}
              height={17}
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.193 4.78a1 1 0 0 1 .027 1.413l-6.166 6.408a1 1 0 0 1-1.428.013L2.293 9.281a1 1 0 0 1 1.414-1.414l2.613 2.612 5.46-5.672a1 1 0 0 1 1.413-.028Z'
                fill={palette.coolGray}
              />
            </svg>
          )}
      </CheckContainer>

      <ErrorContainer>
        <Text style={{ color }}>{error}</Text>
      </ErrorContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CheckContainer = styled.div`
  display: flex;
  margin-right: 10px;
  width: 16px;
  height: 17px;
`;

const ErrorContainer = styled.div`
  display: flex;
`;

const Text = styled.span`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  letter-spacing: 0.05em;
  font-feature-settings: 'liga' off;

  color: ${palette.coolGray};
`;

export default ErrorMessage;
