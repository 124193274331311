import * as Yup from 'yup';

/**
 * Custom hook to build custom UI form validation schemas required by formik UI forms
 *
 * @return { Object } - { getYupShape, textValidator, zipCodeValidator } - Set of functions to be used to create
 * custom UI form validation schemas and validators for custom data types.
 * */
const useYupSchemaBuilder = () => {
  Yup.addMethod(Yup.string, 'noWhitespace', function noWhitespace() {
    return this.transform((value, originalValue) =>
      /^[^-\s][a-zA-Z0-9_\s-]+$/.test(originalValue) ? value : NaN
    );
  });

  const getYupShape = (fields) => Yup.object().shape({ ...fields });
  const textValidator = (
    textConfig = { required: false, noWhitespace: false }
  ) => {
    const { required, noWhitespace } = textConfig;
    if (required && noWhitespace) {
      return Yup.string().required().noWhitespace();
    }
    if (required) {
      return Yup.string().required();
    }
    return Yup.string();
  };
  const zipCodeValidator = () =>
    textValidator({ required: true, noWhitespace: false })
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits');

  const emailValidator = () => {
    const errorMessage =
      'Enter a valid email address to use as your Oshi Health ID';
    return textValidator().required(errorMessage).email(errorMessage);
  };

  return {
    getYupShape,
    textValidator,
    emailValidator,
    zipCodeValidator,
  };
};

export default useYupSchemaBuilder;
