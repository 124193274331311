const checkNameRoute = (pathname) => {
  const regex = /(\/name)(?:\/.+)?/gi;
  if (regex.test(pathname)) {
    let matches = /(\/name)(?:\/.+)?/gi.exec(pathname);
    return matches[1];
  }
  return pathname;
};

export const getCurrentPageStep = (routes, pathname) =>
  routes.find((route) => route.routes.includes(checkNameRoute(pathname)))
    ?.step ?? -1;
