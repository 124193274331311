import React from 'react';
import { setViewportHeight } from '../utils/domHelpers';

/**
 * @function useWindowOrtientationChange
 * @description A hook to handle window orientation change
 * @returns {void}
 */
const useWindowOrtientationChange = () => {
  const handleOrientationChange = () => {
    // Add any logic here to handle orientation change
    // For instance the following funcion call is used to recalculate the viewport height
    // to properly display app's content specially on mobile devices
    setViewportHeight();
  };

  React.useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);
};

export default useWindowOrtientationChange;
