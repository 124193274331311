import React from 'react';

const useComponentFocus = (initialValue = null) => {
  const ref = React.useRef(initialValue || null);

  const setFocusOnComponent = React.useCallback((refByArg = null) => {
    const currentRef = refByArg ? refByArg : ref.current;
    currentRef && currentRef.focus();
  }, [ref]);

  return [ref, setFocusOnComponent];
};

export default useComponentFocus;
