import React, { useState, useRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { palette } from '../theme/palette';
import { CloseIcon } from '../assets/svgs';
import OshiInput from './OshiInput';
import Typography from '../atoms/Typography';
import withTracker from '../wrappers/withTracker';

function GooglePlacesModal({ initialInput, handleModalClose }) {
  const [address, setAddress] = useState(initialInput);
  const googlePlacesInputRef = useRef();
  const history = useHistory();

  function handleChange(value) {
    setAddress(value);
  }

  useEffect(() => {
    googlePlacesInputRef.current.focus();
    window.scrollTo(0, 0);
  }, []);

  const contentAnime = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  function handleNext(googleMapsSuggestedAddress) {
    history.push({
      pathname: '/address',
      state: {
        googleMapsSuggestedAddress,
      },
    });
  }

  function handleSelect(selectedAddress) {
    geocodeByAddress(selectedAddress)
      .then((results) => {
        handleNext({
          addressComponent: results[0].address_components,
          plus_code: results[0].plus_code,
        });
      })
      .catch((error) => console.error('Error', error));
  }

  function handleKeyUp(e) {
    if (e.keyCode === 13 || e.key === 'Enter') {
      handleSelect(e.target.value);
    }
  }

  return (
    <Container style={contentAnime}>
      <PaddedContainer>
        <CloseIconContainer>
          <CloseIcon onClick={handleModalClose} />
        </CloseIconContainer>
        <Form>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            debounce={200}
            onError={(err) => console.log('error here', err)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <>
                <OshiInput
                  ref={googlePlacesInputRef}
                  id='streetAddress'
                  autoFocus
                  type='text'
                  onKeyUp={handleKeyUp}
                  label='Street Address'
                  segmentLabel='Patient Info - Street Address Google Maps Api Input'
                  {...getInputProps()}
                />
                <SuggestionsContainer>
                  {suggestions.map((item) => (
                    <Suggestion
                      {...getSuggestionItemProps(item)}
                      selectedWithKey={item.description === address}
                      onClick={() => handleSelect(item.description)}
                      key={item.placeId}
                    >
                      <Typography>{item.description}</Typography>
                    </Suggestion>
                  ))}
                </SuggestionsContainer>
              </>
            )}
          </PlacesAutocomplete>
        </Form>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled(animated.main)`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: absolute;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;

const PaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  align-items: center;
  padding-top: 20px;
`;

const CloseIconContainer = styled.div`
  width: 100%;
`;

const Form = styled.form`
  height: 190px;
  width: 100%;
  margin-top: 35px;
  @media only screen and (min-width: 429px) {
    margin-top: 20%;
  }
`;

const SuggestionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Suggestion = styled.div`
  display: flex;
  width: 97%;
  align-items: center;
  background: ${(props) =>
    props.selectedWithKey ? palette.cadetBlue : 'transparent'};
  border-radius: 4px;
  padding: 10px;
  padding-left: 3%;
  color: ${(props) =>
    props.selectedWithKey ? palette.white : palette.cadetBlue};
  overflow: auto;
  cursor: default;
  &:hover {
    background: ${palette.cadetBlue};
    color: ${palette.primaryLightColor};
  }
`;

export default withTracker(
  GooglePlacesModal,
  'Signup - Google API - Page View'
);
