export const STORAGE = window.sessionStorage;

/**
 * Retrive an object from session storage.
 * @param  string key
 * @return mixed
 */
export function sessionStorageGet(key) {

  // console.log('=====================================')
  // for (var i = 0; i < STORAGE.length; i++) {
  //   const leKey = STORAGE.key(i);
  //   console.log([leKey, STORAGE.getItem(leKey)]);
  // }
  // console.log('=====================================')
  
  const item = STORAGE.getItem(key);

  if (!item) return false;

  if (item[0] === '{' || item[0] === '[') return JSON.parse(item);

  return item;
}

/**
 * Save some value to session storage.
 * @param string key
 * @param string value
 */
export async function sessionStorageSave(key, value) {
  if (value === undefined) throw new Error("Can't store undefinded value");

  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  if (typeof value !== 'string')
    throw new Error("Can't store unrecognized format value");

  STORAGE.setItem(key, value);
}

/**
 * Remove element from session storage.
 * @param string key
 */
export function sessionStorageRemove(key) {
  STORAGE.removeItem(key);
}
