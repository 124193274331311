import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';
import Typography from './Typography';

function MultiChoiceButton({ type, text, isSelected, onClick, blueStyle }) {
  const animation = useSpring({
    backgroundColor: isSelected ? palette.background : palette.background,
  });
  return blueStyle ? (
    <ButtonFullWidth
      type={type ? type : 'button'}
      onClick={onClick}
      style={animation}
    >
      <Typography
        styles={{
          color: palette.cadetBlue,
          fontSize: '14px',
          fontWeight: '600',
          letterSpacing: '0.04em',
        }}
        type='sixHunderedButton'
      >
        {text}
      </Typography>
    </ButtonFullWidth>
  ) : (
    <Button type={type ? type : 'button'} onClick={onClick} style={animation}>
      <Typography
        styles={{
          color: palette.cadetBlue,
        }}
        type='sixHunderedButton'
      >
        {text}
      </Typography>
    </Button>
  );
}

const Button = styled(animated.button)`
  width: 328px;
  height: 48px;
  border-radius: 4px;
  border-color: ${palette.cadetBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${palette.cadetBlue};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
`;

const ButtonFullWidth = styled(animated.button)`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border-color: ${palette.cadetBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid ${palette.cadetBlue};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
`;

export default MultiChoiceButton;
