import { useEffect, useRef } from 'react';

/**
 * Custom hook to handle async tasks while a component has finished to render.
 *
 * @param {Promise} asyncFn - Promise function to handle any async tasks like API calls
 * @param {Function|React.SetStateAction} onSuccess - Custom function or setState action to save and
 * persist data across UI component.
 */
const useIsAsyncMounted = (asyncFn, onSuccess) => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    asyncFn().then((data) => {
      if (isMounted.current) {
        onSuccess(data);
      }
    });
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);
};

export default useIsAsyncMounted;
