import { uiTypes, userTypes } from '../constants';

export function setIsFetching(isFetching) {
  return {
    type: uiTypes.IS_FETCHING,
    isFetching,
  };
}

export function setShowBanner(showBanner) {
  return {
    type: uiTypes.SHOW_BANNER,
    showBanner,
  };
}

export function setIsNameRoute(isNameRoute) {
  return {
    type: uiTypes.IS_NAME_ROUTE,
    isNameRoute,
  };
}

export function setShowRightSidedBannerVisibility(hideRightSidedBanner) {
  return {
    type: uiTypes.HIDE_RIGHT_SIDED_BANNER,
    hideRightSidedBanner,
  };
}

export function setPatientProfile(patientProfile) {
  return {
    type: userTypes.STORE_PATIENT_PROFILE,
    patientProfile,
  };
}
