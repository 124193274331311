import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';

function CheckCircle({
  isFilled,
  styles,
  children,
  nonFilledColor,
  borderColor,
  onClick,
  isRounded = false,
  componentKey,
}) {
  const colorAnime = useSpring({
    backgroundColor: isFilled ? palette.mediumTurqoise : nonFilledColor,
    borderColor: isFilled ? palette.mediumTurqoise : borderColor,
  });

  function handleClick(e) {
    e.stopPropagation();
    onClick && onClick(componentKey);
  }

  return (
    <Container
      onClick={handleClick}
      style={{ ...colorAnime, ...styles, borderRadius: isRounded ? '50%' : 3 }}
    >
      {children}
    </Container>
  );
}

const Container = styled(animated.div)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid transparent;
`;

export default CheckCircle;
