import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import OshiInput from '../components/OshiInput';
import ResetPasswordFlowContainer from '../components/ResetPasswordFlowContainer';
import regex from '../utils/regex';

const emailValidationSchema = Yup.object({
  email: Yup.string().required('Please enter a valid email.'),
});

const ResetPasswordEmail = () => {
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);

  function validate() {
    const errors = {};
    if (!regex.email.test(formik.values.email)) {
      errors.email = 'Please enter a valid email.';
    }

    return errors;
  }

  const handleOnKeyPress = async (evt) => {
    let isValid;
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
      isValid = await formik.validateForm();
    }

    if (isValid && Object.keys(isValid).length === 0) {
      return handleOnSubmit();
    }

    return isValid && setShowErrors(isValid[Object.keys(isValid)[0]]);
  };

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnSubmit = async () => {
    const validatedForm = await formik.validateForm();
    const email = formik.values.email.toLowerCase();

    if (validatedForm && Object.keys(validatedForm).length > 0) {
      return setShowErrors(true);
    }

    function reset() {
      Auth.forgotPassword(email);
      history.push({
        pathname: '/reset-password-code',
        state: {
          email,
        },
      });
    }

    try {
      return reset();
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        return reset();
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: handleOnSubmit,
    validateOnChange: true,
    validate,
  });

  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  return (
    <ResetPasswordFlowContainer
      onKeyDown={handleOnKeyPress}
      headerText='Reset Password'
      title='To reset your password, please enter the email associated with your Oshi Health account.'
      buttonText='Reset Password'
      errorText={formik.errors.email}
      errors={Object.keys(formik.errors).length > 0 && showErrors}
      onSubmit={handleOnSubmit}
      disabled={!formik.dirty}
    >
      <OshiInput
        id='email'
        autoFocus
        type='text'
        label='Email'
        segmentLabel='Sign In - Reset Password - Email Input'
        isValid={isValid}
        value={formik.values.email}
        onChange={formik.handleChange}
        clearParentErrorOnFocus={clearParentErrorOnFocus}
        error={showErrors && formik.errors.email}
      />
    </ResetPasswordFlowContainer>
  );
};

export default ResetPasswordEmail;
