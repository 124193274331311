import React from 'react';

import { UserContext } from '../utils/context';
import usePatientAPI from './usePatientAPI';

const useAuthPatient = () => {
  const { getAuthUser } = usePatientAPI();
  const { loggedIn } = React.useContext(UserContext);
  const [authUser, setAuthUser] = React.useState(loggedIn);

  React.useEffect(() => {
    !authUser &&
      (async () => {
        try {
          const response = await getAuthUser();
          if (response.success) {
            setAuthUser(response.success);
          }
          if (response.error) {
            throw new Error('getAuthUser error');
          }
        } catch (err) {
          console.log('error', err);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return { authUser };
};

export default useAuthPatient;
