import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import useNewPartnerPageUILogic from '../hooks/useNewPartnerPageUILogic';
import { setIsFetching } from '../actions';
import { pageViewEvent } from '../actions/segment';
import withTracker from '../wrappers/withTracker';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiInput from '../components/OshiInput';
import OshiNextButton from '../components/OshiNextButton';
import Typography from '../atoms/Typography';
import { ROUTES } from '../constants/routes';

import { palette } from '../theme/palette';

/**
 * @function NewPartner
 * @description Custom page that shows New Partner page for the new generic sign up flow.
 * Its particular hybrid functionality makes this UI component to display different UI components.
 * Its main flow when user's current health plan is not yet available for Oshi and this page
 * displays a form-based page to save user's current health plan and employer they are enrolled to.
 * @param {(state: boolean): void} setIsFetching - Redux store action based function to show/hide loading mask screen.
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {React.ReactElement}
 */
const NewPartner = ({ setIsFetching, pageViewEvent }) => {
  const {
    animationDirection,
    setAnimationDirection,
    formik,
    handleJoinWaitlistClick,
  } = useNewPartnerPageUILogic({ setIsFetching, pageViewEvent });

  return (
    <AnimatedRoute
      nextRoute={ROUTES.OFF_BOARDING}
      title={`We'll let you know when Oshi Health is available to you.`}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={false}
    >
      <SubcopyContainer>
        <Typography
          type={'paragraph'}
          styles={{ color: palette.coolGray500, fontWeight: '400' }}
        >
          It looks like Oshi Health isn't yet offered through your employer or
          health plan. Enter your information below and we'll notify you when
          our digestive care becomes available to you.
        </Typography>
      </SubcopyContainer>
      <Form>
        <OshiInput
          id='healthPlan'
          autoFocus
          type='text'
          label='Health Plan'
          onChange={formik.handleChange}
          formik={formik}
          segmentLabel='Patient Info - Health Plan - Waitlist'
          value={formik.values.healthPlan}
          secondaryLabel={'Optional'}
          labelStyles={{
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '22.5px',
          }}
          containerStyles={{
            marginBottom: '24px',
          }}
        />

        <OshiInput
          id='employer'
          type='text'
          label='Employer'
          onChange={formik.handleChange}
          segmentLabel='Patient Info - Employer - Waitlist'
          formik={formik}
          value={formik.values.employer}
          secondaryLabel={'Optional'}
          labelStyles={{
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '22.5px',
          }}
        />
      </Form>
      <OshiNextButton
        disabled={!formik.dirty}
        onClick={handleJoinWaitlistClick}
        buttonTitle='Join Waitlist'
      />
    </AnimatedRoute>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-top: 42px;
`;

const SubcopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 769px) {
    margin: 0 0px 42px 0px;
  }
`;

export default connect(null, { setIsFetching, pageViewEvent })(
  withTracker(NewPartner, 'Signup - Employer Not Listed - Page View')
);
