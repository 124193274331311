import React from 'react';
import { Auth } from 'aws-amplify';
import { UserContext } from '../utils/context';

const UserProvider = ({ children }) => {
  const [loggedIn, setIsLoggedIn] = React.useState(false);

  const checkIfUserIsSignedIn = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setIsLoggedIn(cognitoUser);
      return cognitoUser;
    } catch (error) {
      console.log('error', error);
      setIsLoggedIn(false);
      return false;
    }
  };

  React.useEffect(() => {
    checkIfUserIsSignedIn();
  }, []);

  return (
    <UserContext.Provider value={{ loggedIn, setIsLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
