import React from 'react';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';

function FourOhFour() {
  return (
    <Container>
      <Typography styles={{ fontSize: '30vh' }} type='h1'>
        404
      </Typography>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  justify-content: center;
  align-items: center;
`;

export default withTracker(FourOhFour, 'Onboarding - 404 Page View');
