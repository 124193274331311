import React from 'react';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import Button from '../components/Button';
import Title from '../components/Title';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import { AccountCreationErrorImage } from '../assets/svgs';

function AccountCreationError() {
  function handleTryAgain() {
    window.location =
      'mailto:carecoordinator@oshihealth.com?subject=Error Creating Account - Email Already Exists';
  }

  return (
    <Container>
      <Title />

      <ImageContainer>
        <AccountCreationErrorImage />
      </ImageContainer>

      <TopTextContainer>
        <Typography
          type='h1'
          styles={{
            textAlign: 'center',
            color: palette.darkPrimaryText,
            lineHeight: '26.94px',
            fontFamily: 'Source Serif Pro, serif',
          }}
        >
          There was an error while trying to create your account
        </Typography>
      </TopTextContainer>

      <BottomTextContainer>
        <Typography
          styles={{
            textAlign: 'center',
            color: palette.darkPrimaryText,
            lineHeight: '26.94px',
          }}
        >
          Please contact our Care Support team to help resolve this issue.
        </Typography>
      </BottomTextContainer>

      <TryAgainButtonContainer>
        <Button disabled={false} onClick={handleTryAgain}>
          Contact Care Support
        </Button>
      </TryAgainButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 180px 30px 35px 20px;
  align-items: center;
  @media (max-width: 420px) {
    padding: 140px 16px;
  }

  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TryAgainButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const TopTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 436px;
  margin-top: 32.7px;
  margin-bottom: 24px;
`;

const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 436px;
  margin-bottom: 80px;
`;

export default withTracker(
  AccountCreationError,
  'Signup - Account Creation Error - Page View'
);
