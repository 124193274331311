import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { localStorageGet } from '../utils/localStorageHelper';

const GuardedRoute = ({
  auth,
  component: Component,
  redirectPath = '/name',
  ...restProps
}) => {
  let path = redirectPath;
  const { partner } = localStorageGet('partner');
  if (partner) {
    path = `/name/${partner}`;
  }
  return (
    <Route
      {...restProps}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to={path} />
      }
    />
  );
};

export default GuardedRoute;
