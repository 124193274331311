import { useEffect } from 'react';

const useRedirect = (paths, urlToRedirect) => {
  useEffect(() => {
    if (paths.includes(document.location.pathname)) {
      document.location = urlToRedirect;
    }
  }, [paths, urlToRedirect]);
  return null;
};

export default useRedirect;
