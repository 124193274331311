import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import regex from '../utils/regex';
import { UserContext } from '../utils/context';
import usePatientAPI from './usePatientAPI';
import useURLQueryParam from './useURLQueryParams';
import { localStorageSave } from '../utils/localStorageHelper';

const useSigninPageUILogic = ({
  setIsFetching,
  SignInSigninBtnPressed,
  SigninInvalidEmailOrPasswordEntered,
}) => {
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const signInValidationSchema = Yup.object({
    email: Yup.string().required('Please enter a valid email.'),
    password: Yup.string().required('Please enter a password.'),
  });
  const { setIsLoggedIn } = useContext(UserContext);
  const { signIn } = usePatientAPI();
  const urlQueryParams = useURLQueryParam();

  const handleOnSubmit = async () => {
    const email = formik.values.email.toLowerCase();
    const validatedForm = await formik.validateForm();
    SignInSigninBtnPressed();

    if (validatedForm && Object.keys(validatedForm).length > 0) {
      return setShowErrors(true);
    }

    setIsFetching(true);
    const userNotFoundError = `We could not recognize your email and/or password combination. Please try again.`;
    try {
      const cognitoUser = await signIn({
        email,
        password: formik.values.password,
      });
      setIsLoggedIn(cognitoUser);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      formik.errors.email = userNotFoundError;
      formik.errors.password = userNotFoundError;
      SigninInvalidEmailOrPasswordEntered();
      return setShowErrors(true);
    }
  };

  function validate() {
    const errors = {};
    if (!regex.email.test(formik.values.email)) {
      errors.email = 'Please enter a valid email.';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInValidationSchema,
    onSubmit: handleOnSubmit,
    validateOnChange: true,
    validate,
  });

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnKeyPress = async (evt) => {
    let isValid;
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
      isValid = await formik.validateForm();
    }

    if (isValid && Object.keys(isValid).length === 0) {
      return handleOnSubmit();
    }

    return isValid && setShowErrors(isValid[Object.keys(isValid)[0]]);
  };

  // Checking utm params in URL if sign in page is opened through marketing email
  // to persist them in local storage and save them into book appt API endpoint
  React.useEffect(() => {
    if (urlQueryParams.size > 0) {
      localStorageSave('signinUTMParams', urlQueryParams.toString());
    }
  }, [urlQueryParams]);

  return {
    formik,
    handleOnSubmit,
    handleOnKeyPress,
    setShowErrors,
    showErrors,
    clearParentErrorOnFocus,
    history,
  };
};

export default useSigninPageUILogic;
