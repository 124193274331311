const getRepeatItemArray = (element, amount) => {
  const result = [];

  for (let i = 0; i < amount; i++) {
    result.push(element);
  }
  return result;
};

export default getRepeatItemArray;
