/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import Button from '../components/Button';
import arrowNext from '../img/arrowNext.png';
import arrowPrevious from '../img/arrowPrevious.png';
import ProgressIndicator from '../components/ProgressIndicator';
import withTracker from '../wrappers/withTracker';
import Title from '../components/Title';
import { ValueProp, LandingGetStarted, LandingLogin } from '../actions/segment';
import { palette } from '../theme/palette';
import IllustrationBanner_asset from '../img/illustration_banner.png';
import IllustrationDoctors_asset from '../img/illustration_doctors.png';
import Typography from '../atoms/Typography';
import { IllustrationOshiExplained } from '../assets/svgs';
import useRedirect from '../hooks/useRedirect';

const IllustrationDoctors = () => {
  return <Illustration src={IllustrationDoctors_asset} />;
};
const IllustrationBanner = () => {
  return <Illustration src={IllustrationBanner_asset} />;
};

function Welcome(props) {
  const [index, set] = useState(0);
  const [introduction, setIntroduction] = useState(true);
  const history = useHistory();
  const [direction, setDirection] = useState('-1');
  const { ValueProp, LandingGetStarted } = props;
  useRedirect('[/]', 'https://oshihealth.com/for-individuals/');

  const keys = [1, 2, 3];
  const Copy = [
    'Are You Ready for A More Complete GI Experience?',
    'Your Oshi Health Care Team Is Ready and Waiting for You',
    'Join Today and Your Telehealth Visits Will Be Just $20',
  ];
  const Illustrations = [
    <IllustrationOshiExplained />,
    <IllustrationDoctors />,
    <IllustrationBanner />,
  ];
  const NextAnimation = keyframes`
	0% {left:100% }
	100% { left:0% }
`;
  const PreviousAnimation = keyframes`
	0% {left:-100% }
	100% { left:0% }
`;
  const IntroductionDektop = keyframes`
    0% {opacity:.1;top:20px }
    100% { opacity :1;top:0px }
  }
`;
  const IntroductionMobile = keyframes`
    0% {opacity:.1;top:0px }
    100% { opacity :1;top:0px }
  }
`;
  const IntroductionAnimation =
    window.innerWidth < 768 ? IntroductionMobile : IntroductionDektop;
  const directionAnimation =
    direction === 1 ? NextAnimation : PreviousAnimation;
  const animation = introduction ? IntroductionAnimation : directionAnimation;
  const copyQuantity = Copy.length;

  const Next = () => {
    if (introduction) {
      setIntroduction(false);
    }
    ValueProp(index + 1);
    setDirection(1);
    index < copyQuantity - 1 ? set(index + 1) : set(copyQuantity - 1);
  };
  const Previous = () => {
    if (introduction) {
      setIntroduction(false);
    }
    if (index > 0) {
      setDirection(-1);
      index > 0 ? set(index - 1) : set(0);
    }
    ValueProp(index - 1);
  };
  const clickHandler = () => {
    LandingGetStarted();
    history.push('/name');
  };

  const handlers = useSwipeable({
    onSwipedLeft: Next,
    onSwipedRight: Previous,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <PageContainer {...handlers}>
      <Background />
      <TitleContainer>
        <Title testId='oshi-header' />
      </TitleContainer>

      <CopyWrapper key={keys[index]} animation={animation}>
        <CopyContainer>
          <IllustrationContainer>{Illustrations[index]}</IllustrationContainer>

          <CopyDescription>
            <Typography
              styles={{
                color: palette.cadetBlue,
              }}
              type='h1'
            >
              {Copy[index]}
            </Typography>
          </CopyDescription>
        </CopyContainer>
      </CopyWrapper>

      <FlexContainer>
        <ArrowsContainer>
          <IconContainer>
            <Icon
              src={arrowPrevious}
              onClick={Previous}
              display={index === 0 ? 'none' : 'block'}
            />
          </IconContainer>
          <IconContainer>
            <Icon
              src={arrowNext}
              onClick={Next}
              display={index === copyQuantity - 1 ? 'none' : 'block'}
            />
          </IconContainer>
        </ArrowsContainer>
      </FlexContainer>

      <ProgressContainer>
        <ProgressIndicator index={index} length={Object.keys(Copy).length} />
      </ProgressContainer>

      <BottomStaticContainer>
        <Button bgcolor={palette.cadetBlue} onClick={clickHandler}>
          Book My First Visit
        </Button>
      </BottomStaticContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: static;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: fixed;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  background: linear-gradient(180deg, #fff 0%, #f4faf8 100%);
`;
const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const TitleContainerAnimation = keyframes`
	0% {top: 5px;opacity:0 }
	20%{opacity:0}
	100% { top: 30px;opacity:1 }
`;
const TitleContainerAnimationMobile = keyframes`
	0% {top: 10px;opacity:0 }
	20%{opacity:0}
	100% { top: 10px;opacity:1 }
`;
const TitleContainer = styled.div`
  position:absolute;
  top:30px;
  width:100vw;
  height:30px;
  display:flex;
  position absolute;
  justify-content: center;
  z-index:2;
  animation-name: ${TitleContainerAnimation};
  animation-duration: .9s;
  @media (max-width: 768px) {
    top:10px;
    animation-name: ${TitleContainerAnimationMobile};
  }
`;
const IconContainer = styled.div``;
const Icon = styled.img`
  display: ${(props) => props.display || 'block'};
  height: 32px;
  margin: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ArrowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;
const CopyWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  will-change: transform, opacity;
  animation-name: ${(props) => props.animation};
  animation-duration: 1s;
`;
const CopyContainer = styled.div`
  position: relative;
  top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    top: 60px;
  }
`;
const Illustration = styled.img`
  height: 291px;
`;
const IllustrationContainer = styled.div`
  height: 291px;
  margin: 30px 0px 30px 0px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const CopyDescription = styled.div`
  position: relative;
  width: 396px;
  text-align: center;
  line-height: 27px;
  white-space: pre-line;
  color: #294254;
  letter-spacing: 0.02em;
  @media (max-width: 768px) {
    width: 327px;
    line-height: 150%;
  }
`;
const ProgressContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 550px;
  @media (max-width: 768px) {
    top: 470px;
  }
`;
const BottomStaticContainerAnimation = keyframes`
	0% {top: 550px;opacity:0 }
	20%{opacity:0}
	100% { top: 580px;opacity:1 }
`;
const BottomStaticContainerAnimationMobile = keyframes`
	0% {top: 500px;opacity:0 }
	20%{opacity:0}
	100% { top: 500px;opacity:1 }
`;
const BottomStaticContainer = styled.div`
  position: absolute;
  top: 580px;
  width: 100vw;
  height: 129px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  animation-name: ${BottomStaticContainerAnimation};
  animation-duration: 1s;
  @media (max-width: 768px) {
    top: 500px;
    animation-name: ${BottomStaticContainerAnimationMobile};
  }
`;
export default connect(null, {
  ValueProp,
  LandingGetStarted,
  LandingLogin,
})(withTracker(Welcome, 'Landing Page View'));
