import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import OshiInput from '../components/OshiInput';
import ResetPasswordFlowContainer from '../components/ResetPasswordFlowContainer';
import {
  sessionStorageGet,
  sessionStorageSave,
} from '../utils/sessionStorageHelper';

const validationSchema = Yup.object({
  passcode: Yup.string().required(
    'The code you entered is invalid or expired. Please try again.'
  ),
});

const ResetPasswordCode = () => {
  const location = useLocation();
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState(null);

  const handleOnSubmit = async (values, helpers) => {
    const validatedForm = await helpers.validateForm();
    const passcode = values.passcode.split(' ').join('');
    const { email } = location.state;

    if (!email) {
      console.log('no email', email);
      return history.replace('/reset-password-email');
    }

    if (validatedForm && Object.keys(validatedForm).length > 0) {
      return setShowErrors(true);
    }

    sessionStorageSave('passcode', passcode);

    return history.push({
      pathname: '/reset-password',
      state: {
        email,
        passcode,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      passcode: sessionStorageGet('passcode') || '',
    },
    validationSchema,
    onSubmit: (values, helpers) => handleOnSubmit(values, helpers),
    validateOnChange: true,
  });

  const clearParentErrorOnFocus = () => {
    setShowErrors(false);
  };

  const handleOnFocus = (ev) => {
    if (formik.errors.passcode) {
      sessionStorageSave('passcode', '');
      formik.setValues({ passcode: '' });
      formik.setErrors({});
    }
  };

  const handleOnInputClick = (ev) => {
    if (formik.errors.passcode) {
      ev.target.setSelectionRange(0, 0);
    }
  };

  const handleResetPasswordError = () => {
    document.getElementById('passcode').blur();
    formik.setErrors({
      passcode:
        typeof error === 'string'
          ? error
          : 'Something went wrong. Please try again later.',
    });
    setShowErrors(true);
  };

  const handleOnKeyPress = async (evt) => {
    let isValid;
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
      isValid = await formik.validateForm();
    }

    if (isValid && Object.keys(isValid).length === 0) {
      return formik.handleSubmit();
    }

    return isValid && setShowErrors(isValid[Object.keys(isValid)[0]]);
  };

  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  useEffect(() => {
    if (error && !showErrors) {
      handleResetPasswordError();
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (location.state && location.state.error) {
      setError(location.state.error);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <ResetPasswordFlowContainer
      onKeyDown={handleOnKeyPress}
      headerText='Reset Password'
      title='If you entered a valid email, you will receive a password reset code. Please enter it below.'
      buttonText='Reset Password'
      errorText={formik.errors.passcode}
      errors={Object.keys(formik.errors).length > 0 && showErrors}
      onSubmit={formik.handleSubmit}
      disabled={!formik.dirty}
      linkSegmentEvent='Sign In - Reset Password Code - Didn’t receive an email?'
      linkText='Didn’t receive an email?'
    >
      <OshiInput
        id='passcode'
        autoFocus
        type='tel'
        label='Reset Code'
        pattern='\d*' // only allow numbers
        mask='9 9 9 9 9 9'
        isValid={isValid}
        value={formik.values.passcode}
        onChange={formik.handleChange}
        onFocus={handleOnFocus}
        onClick={handleOnInputClick}
        segmentLabel='Sign In - Reset Password - Passcode Input'
        clearParentErrorOnFocus={clearParentErrorOnFocus}
        error={showErrors && formik.errors.passcode}
      />
    </ResetPasswordFlowContainer>
  );
};

export default ResetPasswordCode;
