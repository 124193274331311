import { API } from 'aws-amplify';
import { localStorageGet } from '../utils/localStorageHelper';

const usePatientLeadAPI = () => {
  const savePatientLead = async (saveType = 'create', data = {}) => {
    const apiMethod = saveType === 'update' ? 'put' : 'post';
    const email = localStorageGet('email');
    try {
      await API[apiMethod]('oshiAPI', '/leads', {
        body: {
          email,
          ...data,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.log('error', error);
    }
  };
  return {
    savePatientLead,
  };
};

export default usePatientLeadAPI;
