import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import OshiHeader from '../components/OshiHeader';
import OshiLink from '../components/OshiLink';
import Button from '../components/Button';
import { palette } from '../theme/palette';
import { createTrackEvent } from '../actions/segment';

const ResetPasswordFlowContainer = ({
  createTrackEvent,
  headerText,
  title,
  buttonText,
  linkText,
  children,
  onKeyDown,
  errors,
  errorText,
  disabled,
  onSubmit,
  linkSegmentEvent,
}) => {
  const history = useHistory();
  return (
    <Container>
      <PaddedContainer onKeyDown={onKeyDown}>
        <OshiHeader
          type='back'
          text={headerText}
          action={() => history.goBack()}
        />
        <Title>{title}</Title>

        <InputsContainer>{children}</InputsContainer>

        {errors && (
          <ErrorContainer>
            <ErrorText>{errorText}</ErrorText>
          </ErrorContainer>
        )}

        {linkText && (
          <LinkContainer>
            <OshiLink
              styles={{ minWidth: 0, color: palette.coolGray500 }}
              buttonTitle={linkText}
              onClick={() => {
                linkSegmentEvent && createTrackEvent(linkSegmentEvent);
                history.replace('reset-password-email');
              }}
              containerStyles={{
                justifyContent: 'center',
              }}
            />
          </LinkContainer>
        )}

        <ButtonContainer>
          <Button disabled={disabled} onClick={onSubmit}>
            {buttonText}
          </Button>
        </ButtonContainer>
      </PaddedContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-height: 100vh;
  min-height: 90vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const PaddedContainer = styled.form`
  width: 90%;
  min-height: 100%;
  display: flex;
  flex: 1;
  margin: 0 24px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  align-items: center;
  padding-top: 50px;
`;

const Title = styled.h1`
  font-size: 15px;
  font-weight: 400;
  font-family: 'Usual', sans-serif;
  color: ${palette.coolGray500};
  margin-top: 25%;
  line-height: 150%;
  text-align: center;
  margin-bottom: 50px;
`;

const InputsContainer = styled.div`
  width: 100%;
  display: flex;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25%;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  margin-bottom: 38px;
`;

const ErrorText = styled.div`
  color: ${palette.error};
  font-family: 'Usual', serif;
  font-size: 12px;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export default connect(null, {
  createTrackEvent,
})(ResetPasswordFlowContainer);
