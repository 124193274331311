import React from 'react';

const typographyConfig = {
  h1: {
    fontSize: '20px',
    fontFamily: 'Usual',
    fontWeight: '600',
  },
  button: {
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Usual',
    lineHeight: '20px',
  },
  subcopy: {
    fontSize: '12px',
    fontFamily: 'Usual',
    lineHeight: '18px',
    letterSpacing: '0.06em',
  },
  subcopyNoHeight: {
    fontSize: '12px',
    fontFamily: 'Usual',
    letterSpacing: '0.06em',
  },
  paragraph: {
    fontSize: '16px',
    fontFamily: 'Usual',
    lineHeight: '24px',
  },
  paragraphBold: {
    fontSize: '16px',
    fontFamily: 'Usual',
    lineHeight: '24px',
    fontWeight: '600',
  },
  boldSmall: {
    fontSize: '12px',
    fontFamily: 'Usual',
    lineHeight: '18px',
    fontWeight: '600',
  },
  boldRegular: {
    fontSize: '18px',
    fontFamily: 'Usual',
    lineHeight: '20px',
    fontWeight: '600',
  },
  boldTitle: {
    fontSize: '18px',
    fontFamily: 'Usual',
    lineHeight: '20px',
    fontWeight: '600',
  },
  eighteenPXRegular: {
    fontSize: '18px',
    fontFamily: 'Usual',
    lineHeight: '20px',
  },
  boldHighlight: {
    fontSize: '14px',
    fontFamily: 'Usual',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  lightSmall: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '17px',
    fontFamily: 'Usual',
  },
  sixHunderedButton: {
    fontWeight: '600',
    fontSize: '14px',
    fontFamily: 'Usual',
  },
  default: {
    fontSize: '14px',
    fontFamily: 'Usual',
    lineHeight: '24px',
  },
};

const Typography = ({ type, children, styles }) => {
  function getElement() {
    switch (type) {
      case 'h1':
        return (
          <h1 style={{ ...typographyConfig[type], ...styles }}>{children}</h1>
        );
      case 'h2':
        return (
          <h2 style={{ ...typographyConfig[type], ...styles }}>{children}</h2>
        );
      case 'eighteenPXRegular':
        return (
          <h2 style={{ ...typographyConfig[type], ...styles }}>{children}</h2>
        );
      case 'subcopy':
        return (
          <p style={{ ...typographyConfig[type], ...styles }}>{children}</p>
        );
      case 'subcopyNoHeight':
        return (
          <p style={{ ...typographyConfig[type], ...styles }}>{children}</p>
        );
      case 'paragraph':
        return (
          <p style={{ ...typographyConfig[type], ...styles }}>{children}</p>
        );
      case 'button':
        return (
          <span style={{ ...typographyConfig[type], ...styles }}>
            {children}
          </span>
        );
      case 'boldSmall':
        return (
          <span style={{ ...typographyConfig[type], ...styles }}>
            {children}
          </span>
        );
      case 'boldRegular':
        return (
          <p style={{ ...typographyConfig[type], ...styles }}>{children}</p>
        );
      case 'boldHighlight':
        return (
          <span style={{ ...typographyConfig[type], ...styles }}>
            {children}
          </span>
        );
      case 'lightSmall':
        return (
          <span style={{ ...typographyConfig[type], ...styles }}>
            {children}
          </span>
        );
      case 'sixHunderedButton':
        return (
          <span style={{ ...typographyConfig[type], ...styles }}>
            {children}
          </span>
        );
      default:
        return (
          <span style={{ ...typographyConfig.default, ...styles }}>
            {children}
          </span>
        );
      // return <div>no type found</div>;
    }

    // return <h1 style={typographyConfig.default}>{children}</h1>;
  }

  return getElement();
};

export default Typography;
