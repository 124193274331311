import React from 'react';

/**
 * @function useDebounce
 * @module hooks/useDebounce
 * @description Custom hook which debounces a input value given its original value and number of milliseconds
 * @param {{value: string, milliSeconds: number}} params - Input value and number of milliseconds to debounce
 * @returns {{debouncedValue: string}}}
 */
const useDebounce = ({ value, milliSeconds }) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(timer);
    };
  }, [value, milliSeconds]);

  return { debouncedValue };
};

export default useDebounce;
