import { segmentTypes } from '../constants';

const segment = (state = {}, action) => {
  switch (action.type) {
    case segmentTypes.PAGE_VIEW_EVENT:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_ACCEPTED:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_EXPANDED:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_SEEN:
      return state;
    case segmentTypes.PRIVACY_PAGE_EXIT:
      return state;
    case segmentTypes.VALUE_PROP_EVENT:
      return state;
    case segmentTypes.LANDING_GET_STARTED:
      return state;
    case segmentTypes.LANDING_LOGIN:
      return state;
    case segmentTypes.INPUT_LEAVE_EVENT:
      return state;
    case segmentTypes.ERROR:
      return state;
    case segmentTypes.SERVER_ERROR:
      return state;
    case segmentTypes.EMAIL_CHECK_LIMIT_EXCEEDED_EXCEPTION:
      return state;
    case segmentTypes.TRACK_TO_STRIPE_CHECKOUT_SESSION:
      return state;
    case segmentTypes.FEE_SCHEDULE_MODAL_VIEWED:
      return state;
    case segmentTypes.LEAVE_FEE_SCHEDULE_MODAL_VIEWED:
      return state;
    case segmentTypes.IDENTIFY_PATIENT:
      return state;
    default:
      return state;
  }
};

export default segment;
