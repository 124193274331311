import React from 'react';

export const capitalize = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

export const getCapitalizedWordsFromURLPath = (path) =>
  path
    .split('-')
    .map((word) => capitalize(word))
    .join(' ');

export function getTitleText(title, isMobile) {
  // web break key is <wbr />
  // mobile break key is <mbr />
  let textArray = [];
  const titleCopy = title;

  if (isMobile) {
    textArray = titleCopy.replace('<wbr />', '').split('<mbr />');
  } else {
    textArray = titleCopy.replace('<mbr />', '').split('<wbr />');
  }

  return textArray;
}

export function getHeadlineListUIComp(list, Component = 'h3') {
  return list.map((text, key) => <Component key={key}>{text}</Component>);
}
