import React from 'react';
import styled from 'styled-components';
import logo from '../img/logo.png';

const Title = ({ testId }) => {
  return (
    <TitleContainer data-testid={testId || ''}>
      <Img src={logo} />
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
    position:absolute;
    top:30px;
    width:100vw;
    height:30px;
    display:flex;
    position absolute;
	  justify-content: center;
	  z-index:2;
`;

const Img = styled.img`
  height: 100%;
`;

export default Title;
