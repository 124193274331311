import React, { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import OshiInput from '../components/OshiInput';
import ResetPasswordFlowContainer from '../components/ResetPasswordFlowContainer';
import regex from '../utils/regex';
import InputErrorMessage from '../atoms/InputErrorMessage';
import usePatientAPI from '../hooks/usePatientAPI';
import { setIsFetching } from '../actions';
import { UserContext } from '../utils/context';
import { sessionStorageSave } from '../utils/sessionStorageHelper';

const ResetPassword = ({ setIsFetching }) => {
  const location = useLocation();
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const { setIsLoggedIn } = React.useContext(UserContext);
  const { signIn } = usePatientAPI();

  function passwordValidate({ password }) {
    const errors = {};

    if (password.length < 8) {
      errors.length = '8 or more characters';
    }

    if (!regex.atLeastOneUpperCase.test(password)) {
      errors.upperCase = 'At least one uppercase letter (A-Z)';
    }

    if (!regex.atLeastOneLetter.test(password)) {
      errors.lowerCase = 'At least one lowercase letter (a-z)';
    }

    if (!regex.atLeastOneDigit.test(password)) {
      errors.digit = 'At least one number (0-9)';
    }

    return errors;
  }

  // eslint-disable-next-line consistent-return
  async function handleOnSubmit(values, helpers) {
    const validationResult = await helpers.validateForm();
    const { email, passcode } = location.state;

    if (validationResult && Object.keys(validationResult).length > 0) {
      return setShowErrors(true);
    }

    try {
      setIsFetching(true);
      const response = await new Promise((resolve) => {
        Auth.forgotPasswordSubmit(email, passcode, values.password)
          .then(() => resolve({ success: true }))
          .catch((err) => resolve({ error: err }));
      });
      if (response.success) {
        const loggedInUser = await signIn({
          email,
          password: values.password,
        });
        setIsLoggedIn(loggedInUser);
        sessionStorageSave('passcode', '');
      } else {
        switch (response.error.code) {
          case 'LimitExceededException':
            throw new Error('Too many attempts. Please try again later.')
              .message;
          default:
            throw new Error(
              'The code you entered is invalid or expired. Please try again.'
            ).message;
        }
      }
    } catch (error) {
      setIsFetching(false);
      history.replace({
        pathname: '/reset-password-code',
        state: {
          email,
          error,
        },
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validate: passwordValidate,
    onSubmit: (values, helpers) => handleOnSubmit(values, helpers),
  });

  function clearParentErrorOnFocus() {
    setShowErrors(false);
  }

  const EnterKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
    if (
      evt.keyCode === 13 &&
      formik.dirty &&
      Object.keys(formik.errors).length === 0
    ) {
      formik.handleSubmit();
    } else if (evt.keyCode === 13 && formik.values.password === '') {
      const errorsArray = Object.keys(formik.errors);
      setShowErrors(formik.errors[errorsArray[0]]);
      setShowErrors(true);
    }
    if (formik.dirty) {
      setShowErrors(false);
    }
    const errorsArray = Object.keys(formik.errors);
    if (evt.keyCode === 13 && errorsArray.length > 0) {
      if (errorsArray.length > 0) {
        return setShowErrors(true);
      }
    }
  };

  return (
    <ResetPasswordFlowContainer
      onKeyDown={EnterKeyPress}
      headerText='Reset Password'
      title='Please enter a new password'
      buttonText='Reset Password'
      linkText='Didn’t receive an email?'
      linkSegmentEvent='Sign In - Reset Password - Didn’t receive an email?'
      onSubmit={formik.handleSubmit}
      disabled={!formik.dirty}
      errorText='Create a secure password to protect your health information'
      errors={Object.keys(formik.errors).length > 0 && showErrors}
    >
      <InputContainer>
        <OshiInput
          id='password'
          type='password'
          label='Password'
          autoFill
          error={showErrors}
          autoFocus
          showCheckMark
          segmentLabel='Sign In - Reset Password - New password Input'
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {!showErrors && (
          <ErrorsContainer>
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.length}
              error='Minimum of 8 characters'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.upperCase}
              error='At least one uppercase letter (A-Z)'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.lowerCase}
              error='At least one lowercase letter (a-z)'
            />
            <InputErrorMessage
              showCheckMark
              formik={formik}
              value={formik.values.password}
              isValid={formik.dirty && !formik.errors.digit}
              error='At least one number (0-9)'
            />
          </ErrorsContainer>
        )}
      </InputContainer>
    </ResetPasswordFlowContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
`;

export default connect(null, { setIsFetching })(ResetPassword);
