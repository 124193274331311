import React from 'react';
import { Route } from 'react-router-dom';

export default function ProtectedRoute({ path, childCmp: ChildCmp }) {
  return (
    <Route path={path}>
      <ChildCmp />
    </Route>
  );
}
