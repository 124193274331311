import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiHeader from '../components/OshiHeader';
import withTracker from '../wrappers/withTracker';
import {
  TrackToStripeCheckoutSession,
  FeeScheduleModalView,
  LeaveFeeScheduleModalView,
} from '../actions/segment';
import { CashPayImage } from '../assets/svgs';
import Button from '../components/Button';

function MembershipCashPay() {
  const history = useHistory();
  const oneMonthFromNow = moment().add(1, 'month').format('MM/DD/yy');

  function handleOnBook() {
    history.push('first-appointment-book');
  }

  return (
    <Container>
      <OshiHeader action={history.goBack} type='back' />

      <PaddedContainer>
        <Typography
          styles={{
            color: palette.darkPrimaryText,
            textAlign: 'center',
            marginBottom: '30px',
          }}
        >
          Take the next step to digestive relief. Join Oshi Health and make an
          appointment with our care team today.
        </Typography>
        <div style={{ marginBottom: 34 }}>
          <FlexRowContainer>
            <FlexColumnOne>
              <FlexCenterBottomNine>
                <Typography styles={{ color: palette.darkPrimaryText }}>
                  Membership Fee
                </Typography>
              </FlexCenterBottomNine>

              <div>
                <Typography
                  type='subcopy'
                  styles={{ color: palette.darkPrimaryText }}
                >
                  Subscription period
                </Typography>
              </div>
            </FlexColumnOne>

            <ColumnFlexEnd>
              <FlexCenterBottomNine
                style={{
                  marginBottom: 9,
                }}
              >
                <LineThroughText style={{ marginRight: 9 }} type='subcopy'>
                  $20
                </LineThroughText>
                <Typography
                  type='boldRegular'
                  styles={{ color: palette.mediumTurqoise }}
                >
                  $1
                </Typography>
                <Typography
                  styles={{ color: palette.darkPrimaryText }}
                  type='boldRegular'
                >
                  *
                </Typography>
              </FlexCenterBottomNine>
              <div>
                <Typography
                  type='subcopyNoHeight'
                  styles={{ color: palette.darkSecondaryText }}
                >
                  Today until {oneMonthFromNow}
                </Typography>
              </div>
            </ColumnFlexEnd>
          </FlexRowContainer>
        </div>

        <BannerContainer>
          <Banner>
            <Typography styles={{ marginBottom: '6px' }} type='button'>
              Visits with our Care Team
            </Typography>

            <TopBannerContainer>
              <div>
                <CashPayImage />
              </div>
              <ChargedHoursContainer>
                <Typography
                  style={{ color: palette.darkPrimaryText }}
                  type='subcopy'
                >
                  Charged 24 hours <br />
                  prior to visit
                </Typography>
              </ChargedHoursContainer>
            </TopBannerContainer>

            <div>
              <Typography
                style={{ color: palette.darkPrimaryText }}
                type='subcopy'
              >
                We’d like to dedicate 45 minutes to your initial consultation
                with a GI Nurse Practitioner so we can assess your concerns and get you started
                on the journey to better health.
              </Typography>
            </div>
          </Banner>
        </BannerContainer>

        <BottomTextContainer>
          <Typography
            type='subcopy'
            styles={{ color: palette.darkPrimaryText }}
          >
            * $20 monthly membership fee applies after the first 30 days
          </Typography>
        </BottomTextContainer>

        <ButtonContainer>
          <Button
            styles={{
              width: '278px',
            }}
            onClick={handleOnBook}
          >
            Book Your First Appointment
          </Button>
        </ButtonContainer>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const PaddedContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  padding-top: 10%;

  @media (max-width: 420px) {
    padding-top: 25%;
  }
`;

const LineThroughText = styled.p`
  font-size: 12px;
  color: ${palette.greyText};
  text-decoration: line-through;
  font-family: 'Usual';
`;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnFlexEnd = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`;

const FlexCenterBottomNine = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

const FlexColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BannerContainer = styled.div`
  display: flex;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
`;

const Banner = styled.div`
  display: flex;
  width: 343px;
  flex-direction: column;
  background: ${palette.lightTransparent};
  border: 0.05px solid ${palette.transitionScreen};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 22px 16px;
`;

const TopBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChargedHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;

  @media (max-width: 420px) {
    padding: 0 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default connect(null, {
  TrackToStripeCheckoutSession,
  FeeScheduleModalView,
  LeaveFeeScheduleModalView,
})(withTracker(MembershipCashPay, 'Onboarding - Membership Summary Page View'));
