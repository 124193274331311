import React from 'react';
import { useLocation } from 'react-router-dom';

const useBrowserTabActiveness = (userTabActivenessEvent) => {
  const location = useLocation();

  const listener = React.useCallback(
    (ev) => {
      userTabActivenessEvent(location.pathname, document.hidden);
    },
    [location, userTabActivenessEvent]
  );

  React.useEffect(() => {
    document.addEventListener('visibilitychange', listener);
    return () => document.removeEventListener('visibilitychange', listener);
    // eslint-disable-next-line
  }, [listener]);
};

export default useBrowserTabActiveness;
