import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import OshiHeader from '../components/OshiHeader';
import withTracker from '../wrappers/withTracker';
import Typography from '../atoms/Typography';

import {
  ConfirmAppointmentConfirmed,
  ConfirmAppointmentReschedule,
  createTrackEvent,
  TrackToStripeCheckoutSession,
} from '../actions/segment';
import { setIsFetching } from '../actions';

import { palette } from '../theme/palette';

const CancelationPolicy = () => {
  const history = useHistory();

  return (
    <Container>
      <PaddedContainer>
        <OshiHeader
          type='close'
          action={history.goBack}
          testId='appointment-confirm-header'
        />
        <Typography
          styles={{
            marginTop: '70px',
            color: palette.darkPrimaryText,
            textAlign: 'left',
            fontSize: '32px',
            lineHeight: '48px',
            fontWeight: '500',
          }}
        >
          Cancellation and Refund Policy
        </Typography>
        <Typography
          type='subcopy'
          styles={{
            color: palette.darkPrimaryText,
            fontWeight: '500',
            lineHeight: '24px',
            fontSize: '14px',
            letterSpacing: '0.04em',
            marginBottom: '30px',
          }}
        >
          Membership, Insurance and Pricing
        </Typography>
        <BottomTextContainer>
          <Typography
            type='subcopy'
            styles={{
              color: palette.darkPrimaryText,
              fontWeight: '600',
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.04em',
            }}
          >
            Cancellation Policy for Subscription
          </Typography>
          <Typography
            type='subcopy'
            styles={{
              color: palette.darkPrimaryText,
              fontWeight: '500',
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.04em',
              marginTop: '22px',
            }}
          >
            For our paid members, you authorize us to charge you the $20 Monthly
            Membership Fee to your chosen payment method at the time of the
            initial payment and each month thereafter until you cancel. You must
            cancel your membership before it renews to avoid being charged the
            monthly membership fee for the next month. You can cancel your
            membership at any time. If you cancel your membership and your
            subscription term has not expired, you may continue to use the
            services until the end of your then-current membership period and
            your membership will not be renewed after your then-current
            membership period expires. You are not eligible for a prorated
            refund of any portion of the monthly membership fee when you cancel.
          </Typography>
          <Typography
            type='subcopy'
            styles={{
              color: palette.darkPrimaryText,
              fontWeight: '600',
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.04em',
              marginTop: '22px',
            }}
          >
            How to Cancel Your Account
          </Typography>
          <Typography
            type='subcopy'
            styles={{
              color: palette.darkPrimaryText,
              fontWeight: '500',
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.04em',
              marginTop: '22px',
              marginBottom: '40px',
            }}
          >
            Monthly membership can be cancelled at any time. If you need to
            cancel you can message your Care Coordinator through your Oshi
            Health mobile app or via email at{' '}
            <StyledLink
              styles={{
                fontSize: '16px',
              }}
              href='mailto: abc@example.com'
            >
              carecoordinator@oshihealth.com{' '}
            </StyledLink>
            to process this request.
          </Typography>
        </BottomTextContainer>
      </PaddedContainer>
    </Container>
  );
};

const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  flex-direction: column;
  align-items: center;
`;

const PaddedContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
`;

const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled('a')`
  color: ${palette.mediumTurqoise};
  letter-spacing: 0.06em;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
`;
const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  ConfirmAppointmentConfirmed,
  ConfirmAppointmentReschedule,
  createTrackEvent,
  TrackToStripeCheckoutSession,
  setIsFetching,
})(withTracker(CancelationPolicy, 'Appointment Confirm Page View'));
