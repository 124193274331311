/* eslint-disable import/prefer-default-export */
import { localStorageGet } from './localStorageHelper';

export const getBirthdate = () => {
  try {
    return new Date(
      localStorageGet('dob').birthday.split(' / ').join('/')
    ).getTime();
  } catch (error) {
    console.log('getBirthdate error', error);
    return null;
  }
};
