import React, { useEffect } from 'react';

function DeeplinkHandle() {
  useEffect(() => {
    window.location.replace('oshihealth://profile');
  }, []);
  return <div />;
}

export default DeeplinkHandle;
