import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Skip = () => {
  const history = useHistory();
  return (
    <PageContainer>
      <FlexContainer>
        <Skipped onClick={() => history.push('first-appointment-description')}>
          skipped
        </Skipped>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  @media (max-width: 768px) {
    height: 100%;
    z-index: 3;
  }
`;
const FlexContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
`;
const Skipped = styled.div`
  cursor: pointer;
`;

export default Skip;
