import { combineReducers } from 'redux';
import segment from './segment';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import appointmentReducer from './appointmentReducer';

const rootReducer = combineReducers({
  appointmentReducer,
  segment,
  uiReducer,
  userReducer,
});

export default rootReducer;
