import { userTypes } from '../constants';

const userReducer = (state = { patientProfile: false }, action) => {
  switch (action.type) {
    case userTypes.STORE_PATIENT_PROFILE:
      return { ...state, patientProfile: action.patientProfile };
    default:
      return state;
  }
};

export default userReducer;
