export const appointmentStatuses = {
  PENDING: 'Pending', // has 3 different states
  FINISHED: 'Finished', // should not be returned from the BE
  MISSED: 'Missed',
  CANCELED: 'Canceled', // should not be returned from the BE
  RESCHEDULED: 'Rescheduled', // should not be returned from the BE
  MISSED_DISSMISED: 'Dismissed', // new status tell edwin
  INTERNAL_MISSED: 'Internal_Missed',
  INTERNAL_PENDING: 'Internal_Pending',
};

export const ONBOARDING_APPT_TYPE = 'onboarding';
export const FOLLOW_UP_APPT_TYPE = 'followup';
export const REFERRAL_APPT_TYPE = 'referral';

export const APPOINTMENT_ERROR = {
    TIMESLOT_TAKEN: 'TIMESLOT_TAKEN'
};
