export const STORAGE = window.localStorage;

/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
export function localStorageGet(key) {
  const item = STORAGE.getItem(key);

  if (!item) return false;

  if (item[0] === '{' || item[0] === '[') return JSON.parse(item);

  return item;
}

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
export async function localStorageSave(key, value) {
  if (value === undefined) throw new Error("Can't store undefinded value");

  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  if (typeof value !== 'string')
    throw new Error("Can't store unrecognized format value");

  STORAGE.setItem(key, value);
}

/**
 * Remove element from local storage.
 * @param string key
 */
export function localStorageRemove(key) {
  STORAGE.removeItem(key);
}
