import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { pageViewEvent } from '../actions/segment';
import { setIsFetching } from '../actions';

const withTracker = (WrappedComponent, pageName) => {
  const HOC = (props) => {
    useEffect(() => {
      props.pageViewEvent(pageName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} />;
  };

  return connect(null, {
    setIsFetching,
    pageViewEvent,
  })(HOC);
};

export default withTracker;
