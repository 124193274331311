/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import React from 'react';

const useWindowSize = () => {
  const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      currentHeight:
        window.innerHeight + window.document.body.getBoundingClientRect().top,
      isMobile: window.innerWidth <= 768,
    };
  }

  React.useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  return { screenSize };
};

export default useWindowSize;
